<template>
  <section>
    <div class="content-header">
      <h2>Models of career resilience</h2>
    </div>
    <div class="content-wrapper">
      <p>We can think of career resilience as fitting into a larger model of career self-management resources. </p>
      <p>Andreas Hirschi, a leading researcher on career development and counselling has developed the <strong class="bold">career resources model</strong>, in which he proposes four critical career resources that are essential for career management in the contemporary context: human capital resources, social resources, psychological resources and identity resources. </p>
      <p>In this model, career resilience fits under the category of psychological resources. However, it is enhanced by factors such as education, transferable skills, having a strong sense of identity, knowledge of how to navigate the labour market and robust and diverse social networks.</p>
      <!-- <img src="@/assets/img/_illustrations/personal-learn-5.svg" width="400px" height="400px" class="img-fluid d-block mx-auto" alt="A matrix depicting the five factor model of resilience" > -->
      <!-- <p class="text-center">Career resources model</p> -->
      <img src="@/assets/img/_illustrations/career-learn-3.svg" width="600px" height="600px" class="img-fluid d-block mx-auto pt-4" alt="" >
        <p class="text-center pt-5">Career resources model</p>
        <p class="text-center">Source: Adapted from Hirschi, A. (2012)</p>
      <p class="lm">Learn more</p>
      <ul>
        <li>Interested in learning more about Hirschi’s career resources model? Check out <a href="https://www.tandfonline.com/doi/abs/10.1080/03069885.2012.700506" target="_blank">The Career Resources Model: An Integrative Framework for Career Counsellors.</a></li>
        <li>Want to learn more about your own career self-management skills? Try the <a href="http://www.cresogo.com/crqmainpage" target="_blank">Career Resources Questionnaire</a>.</li>
      </ul>
    </div>
  </section>
</template>

<script>
// import Accordion from '../components/Accordion.vue'
// import SidebarMenu from '../components/SidebarMenu.vue'
// import {
//   Hooper,
//   Slide,
//   Navigation as HooperNavigation,
//   Pagination as HooperPagination
// } from 'hooper'
// import 'hooper/dist/hooper.css'

export default {
  name: '03',
  components: {
    // SidebarMenu,
    // Accordion,
    // Hooper,
    // Slide,
    // HooperNavigation,
    // HooperPagination
  },
  data () {
    return {
      publicPath: process.env.BASE_URL
    }
  },

  mounted () {
  }
}
</script>

<style>
</style>
